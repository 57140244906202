<template>
<div>
  <profile-form v-on:complete="profileComplete"/>
</div>
</template>

<script>
import profileForm from "../../pages/profileForm";
import { db } from "@/services/db";
import { loadObj } from "@/services/dbutils";
import { getLog } from "@/services/log";
let log = getLog("form");

export default {
  components: {
    profileForm,
  },
  methods: {
    async profileComplete(user) {
      log.log("profileComplete");
      let doc = db.collection("LiveUsers").doc(user.id);
      let olduser = await loadObj(doc);
      let id = new Date().toISOString();
      db.collection(`LiveUsers/${user.id}/archive`).doc(id).set(olduser).then(() => {
        log.log("archived", olduser);
      });
      doc.set(user, {merge:true}).then(() => {
        log.log("updated");
      });
    }
  }
}
</script>
